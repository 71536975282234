<template>
    <div v-if="popup">
        <div class="popup">
            <div class="popup-inner">
                <div class="modal-header mb-4">
                    <h1 class="text-white">Rios EAD</h1>
                    <button type="button" v-on:click="closePopUp()" class="close close-light" data-dismiss="modal">X</button>
                </div>
                <div class="popup-body">
                    <ul style="background: #fff; padding:40px; border-radius:5px;">
                        <h2>VOCÊ ACABA DE GANHAR <span style="font-size:50px;color:red;">60%</span> DE DESCONTO NO VALOR DO SEU CURSO DE PÓS-GRADUAÇÃO!</h2>
                        <li>Certificado reconhecido pelo MEC</li>
                        <li>Estude 100% online com início imediato</li>
                        <li>TCC opcional</li>
                        <li>Conclusão a partir de 4 meses</li>
                        <br>
                        <br>
                        Para garantir o seu desconto cadastre suas informações abaixo para registrar em nosso sistema.
                    </ul>
                    <p>A Faculdade irá entrar em contato com os selecionados.</p>

                    <!-- Call Me Form -->
                    <form @submit.prevent="postLead">
                        <div class="form-group">
                            <label class="label-control">Nome</label>
                            <input type="text" class="form-control" placeholder="Nome" v-model="lead.name" required>
                        </div>
                        <div class="form-group">
                            <label class="label-control">Telefone</label>
                            <input type="text" class="form-control" placeholder="Telefone" v-model="lead.phone" required>
                        </div>
                        <div class="form-group">
                            <label class="label-control">Email</label>
                            <input type="email" class="form-control" placeholder="Email" name="email" v-model="lead.email" required>
                        </div>
                        <div class="alert alert-secondary text-center" v-if="isSuccess">Cadastro realizado com sucesso!</div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-secondary float-right">ENVIAR</button>
                        </div>
                    </form>
                    <!-- end of call me form -->
                </div>
            </div>
        </div>
        <div class="popup-background"></div>
    </div>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
    data(){
        return {
            lead:{
                name: '',
                phone: '',
                email: '',
            },
            isSuccess: false,
        }
    },
    mounted() {
        this.callPopUp()
    },
    computed: {
        ...mapState({
            popup: state => state.popup
        })
    },
    methods: {
        ...mapMutations({
            setPopUp: 'SET_POPUP'
        }),
        closePopUp () {
            this.setPopUp(false)
        },
        callPopUp: function () {
            setTimeout(() => {
                this.setPopUp(true)
            }, 1500);
        },
        postLead() {
            axios.post(
                `https://facuminasbrasil.com.br/admin/api/postLead`,
                this.lead
            ).then(response => {
                this.isSuccess = true;
                console.log(response);
                setTimeout(() => {
                    this.setPopUp(false)
                }, 1000);
                
            });
        }
    }
}
</script>

<style scoped>
.text-white{
    font-weight: bold;
}
.popup{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-background{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.6);
}
.popup-inner{
    background: #EAD518;
    background: -moz-linear-gradient(235deg, #EAD518 0%, #004724 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #EAD518), color-stop(100%, #004724));
    background: -webkit-linear-gradient(235deg, #EAD518 0%, #004724 100%);
    background: -o-linear-gradient(235deg, #EAD518 0%, #004724 100%);
    background: -ms-linear-gradient(235deg, #EAD518 0%, #004724 100%);
    background: linear-gradient(235deg, #EAD518 0%, #004724 100%);
    padding: 40px;
    width: 40vw;
    position: absolute;
    top:0px;
    margin: 28px auto;
}
</style>